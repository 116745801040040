
<template>
  <div>
    <!-- 顶部 -->
    <div v-if="showIndex < 10" class="el-header bg-all flex-b">
      <div>
        湖北海行科技有限公司<span style="margin-left:30px;"
          >致力于让每个家庭都能享受安全、高品质的家庭维修服务</span
        >
      </div>
      <div v-if="userName == ''" class="flex-b" style="width: 350px">
        <div class="flex-c" @mouseenter="moveItems(6)" @mouseleave="liveItems()" style="cursor: pointer;z-index:999">
          小程序   <img style="width: 20px;margin-left:6px;" src="../assets/image/xj.png" />
          <div v-if="moveItem == 6" class="xcx">
            <el-image
                :src="require('../assets/image/bgm.png')"
                class="tttqq"
                style="width: 200px;z-index:8;"
                alt=""
              >
              </el-image>
              <el-image
                :src="require('../assets/image/ewm.png')"
                class="tttqq1"
                style="width: 160px;z-index:10;"
                alt=""
              >
              </el-image>
              <div class="dddww">
                佳修匠小程序
              </div>
          </div>
        </div>
        <div class="flex-c p-r" @mouseenter="moveItems(7)" @mouseleave="liveItems()" style="cursor: pointer;z-index:999">
          公众号 <img style="width: 20px;margin-left:6px;" src="../assets/image/xj.png" />
          <div v-if="moveItem == 7" class="xcx">
            <el-image
                :src="require('../assets/image/bgm.png')"
                class="tttqq"
                style="width: 200px;z-index:8;"
                alt=""
              >
              </el-image>
              <el-image
                :src="require('../assets/image/gzh.png')"
                class="tttqq1"
                style="width: 160px;z-index:10;"
                alt=""
              >
              </el-image>
              <div class="dddww">
                佳修匠公众号
              </div>
          </div>
        </div>
        <div class="flex-c" style="cursor: pointer"> <img style="width: 16px;margin-right:8px;" src="../assets/image/dh.png" /> 4000-027-282</div>
      </div>
    </div>
    <!-- 顶部end... -->

    <!-- 右侧悬浮框 -->
    <div class="fxr">
      <div @mouseenter="moveItems(1)" @mouseleave="liveItems()" @click="addOrder" class="tx-center border-bottom p-r" style="padding:20px 0;cursor: pointer">
        <img style="width: 26px" src="../assets/image/f1s.png" />
        <div class="cl-all">免费预约</div>
        <div class="infof" style="left:-257px;text-align:left;" v-if="moveItem == 1">
          <div>免费在线预约</div>
          <div class="cl-info tx-14" style="margin-top:4px;">点击快速在线预约，安全又便捷</div>
        </div>
      </div>
      <div @mouseenter="moveItems(2)" @mouseleave="liveItems()" class="tx-center p-r border-bottom" style="padding:20px 0;cursor: pointer">
        <img style="width: 26px" src="../assets/image/f2.png" />
        <div>手机下单</div>
        <div class="infof" style="left:-202px;" v-if="moveItem == 2">
          <div>
            <img style="width: 140px;" src="../assets/image/ewm.png" alt="" />
          </div>
          <div class="cl-info tx-14" style="margin-top:4px;">微信扫码关注小程序<br/>快捷下单</div>
        </div>
      </div>
      <div @mouseenter="moveItems(3)" @mouseleave="liveItems()" class="tx-center p-r border-bottom" style="padding:20px 0;cursor: pointer">
        <img style="width: 26px" src="../assets/image/f3.png" />
        <div>电话咨询</div>
        <div class="infof" style="left:-226px;text-align:left;" v-if="moveItem == 3">
          <div>电话咨询:4000-027-282</div>
          <div class="cl-info tx-14" style="margin-top:4px;">服务时间9:00~20:00</div>
        </div>
      </div>
     <!-- <div @mouseenter="moveItems(4)" @mouseleave="liveItems()" class="tx-center p-r border-bottom" style="padding:20px 0;cursor: pointer">
        <img style="width: 26px" src="../assets/image/f4.png" />
        <div>在线客服</div>
        <div class="infof" style="left:-224px;text-align:left;" v-if="moveItem == 4">
          <div>点击立即与客服交流</div>
          <div class="cl-info tx-14" style="margin-top:4px;">客服在线时间:9:00~20:00</div>
        </div>
      </div> -->
      <div class="tx-center border-bottom" @click="toTop" style="padding:20px 0;cursor: pointer">
        <img style="width: 26px" src="../assets/image/f5.png" />
        <div>返回顶部</div>
      </div>
    </div>
    <!-- 右侧悬浮框end... -->

    <!-- 内容区域 -->
    <div  class="home-container">
      <div class="ql">
        <div class="flex-bc pds">
          <div>
            <img style="width: 100px" src="../assets/image/webLogo.png" />
          </div>
          <div style="min-width:600px" v-if="showIndex < 10" class="flex-bc">
            <div @click="selectTabs(1)" :class="selectTab == 1 ? 'tabs' : 'tabn'">首页</div>
            <div @click="selectTabs(2)" :class="selectTab == 2 ? 'tabs' : 'tabn'">维修标准</div>
            <div @click="selectTabs(3)" :class="selectTab == 3 ? 'tabs' : 'tabn'">全部服务</div>
            <div @click="selectTabs(4)" :class="selectTab == 4 ? 'tabs' : 'tabn'">关于我们</div>
            <div>
              <div class="flex-c klk">
                <el-input
                clearable
                class="myi"
                placeholder="请输入"
                v-model="queryInfo.name"
                >
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>

                <el-button
                  style="margin-left: -3px; z-index: 100; border-radius: 0 4px 4px 0"
                  type="primary"
                  @click="search"
                >
                  搜索
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- 面包屑 -->
      <div style="padding-bottom:20px;" class="ql" v-if="cardList.length > 1">
        <div class="flex-c">
          <div @click="clickCard(item)" style="font-size:16px;cursor: pointer" :class="(index+1) == cardList.length ? 'ft-600 flex-c' : 'flex-c cl-info'" v-for="(item, index) in cardList" :key="index">{{item.name}} <i v-if="!((index+1) == cardList.length)" style="font-size:20px;padding:0 5px;" class="el-icon-arrow-right"></i> </div>
        </div>
      </div>


      <el-dialog
      :close-on-click-modal="false"
      title=""
      :visible.sync="addDialogVisible"
      width="45%"
      @close="addDialogClosed"
    >
      <!--内容主体区域-->
      <div class="tx-25 tx-center">免费预约</div>

      <div class="flex-center mg-t">
        <div style="width:50%;">
          <div class="inpyy p-r" style="z-index:9;">
            <div class="flex-bc" @click="showAllTq = !showAllTq">
              <div v-if="!indexNameq" style="color:#c4c4c4;">请选择项目</div>
              <div v-else class="cl-00">{{indexNameq}}</div>
              <div style="color:#999999;"><i class="el-icon-arrow-down"></i></div>
            </div>
            <div class="vtv">
              <div v-if="showAllTq" :class="showTypeqq.typeId == item.typeId ? 'bg-all flex-bc cl-white' : 'flex-bc' " style="padding:8px 16px;" v-for="item in tabList"
                @mouseenter="showTyqq(item)" 
                @mouseleave="noshowTyqq()"
                :key="item.typeId"
              >
                <div>{{item.typeName}}</div>
                <div><i class="el-icon-arrow-right"></i></div>
                <div class="tyt2" v-if="showTypeqq.typeId == item.typeId">
                  <div class="tx-18 ft-600">{{showTypeqq.typeName}}</div>
                  <div class="flex tx-15" style="flex-wrap:wrap;line-height:40px;"> 
                    <div @click.stop="selectProqq(item,items)" style="margin-right:30px;" :class="showTyinqq === indexs ? 'cl-all cu' : 'cu'" v-for="(items,indexs) in showTypeqq.projects" @mouseenter="showTyiqq(indexs)" @mouseleave="noshowTyiqq()" :key="indexs">{{items.name}}</div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>
      
      <div class="flex-center mg-t">
        <div style="width:50%;">
          <el-input style="width:100%;" placeholder="请输入服务地址"  v-model="addForms.address"></el-input>
        </div>
      </div>
      <div class="flex-center mg-t">
        <div style="width:50%;">
          <el-input style="width:100%;" placeholder="请输入手机号"  v-model="addForms.phone"></el-input>
        </div>
      </div>
      <div class="flex-center mg-t">
        <div class="p-r" style="width:50%;">
          <el-input style="width:100%;" placeholder="请输入验证码"  v-model="addForms.code"></el-input>
          <span v-if="!timecode" class="btncc cl-all" @click="getYzmt">获取验证码</span>
					<span v-if="timecode" class="btncc cl-info">{{num}}秒重新获取</span>
        </div>
      </div>
   
      <span slot="footer" class="dialog-footer">
        <div class="tx-center">
          <el-button round style="width:240px;font-size:16px;" type="primary" @click="addproject">立即预约</el-button>
        </div>
      </span>
    </el-dialog>

      <!-- 首页 -->
      <div v-if="selectTab == 1">
        <div class="p-r">
          <img
              style="width: 100%; display: block"
              src="../assets/image/index.png"
              alt=""
            />
          <div class="opop bg-white">
            <div class="ft-600 mg-t tx-25">快速预约</div>
            <div class="kkk">
              <div class="flex-center mg-t20">
                <div style="width:92%;">
                  <div class="inpy p-r" style="z-index:9;">
                    <div class="flex-bc" @click="showAllT = !showAllT">
                      <div v-if="!indexName" style="color:#c4c4c4;">请选择项目</div>
                      <div v-else class="cl-00">{{indexName}}</div>
                      <div style="color:#999999;"><i class="el-icon-arrow-down"></i></div>
                    </div>
                    <div class="vtv">
                      <div v-if="showAllT" :class="showTypeq.typeId == item.typeId ? 'bg-all flex-bc cl-white' : 'flex-bc' " style="padding:8px 16px;" v-for="item in tabList"
                        @mouseenter="showTyq(item)" 
                        @mouseleave="noshowTyq()"
                        :key="item.typeId"
                      >
                        <div>{{item.typeName}}</div>
                        <div><i class="el-icon-arrow-right"></i></div>
                        <div class="tyt1" v-if="showTypeq.typeId == item.typeId">
                          <div class="tx-18 ft-600" style="text-align:left;">{{showTypeq.typeName}}</div>
                          <div class="flex tx-15" style="flex-wrap:wrap;line-height:40px;"> 
                            <div @click.stop="selectProq(item,items)" style="margin-right:30px;" :class="showTyinq === indexs ? 'cl-all cu' : 'cu'" v-for="(items,indexs) in showTypeq.projects" @mouseenter="showTyiq(indexs)" @mouseleave="noshowTyiq()" :key="indexs">{{items.name}}</div>
                          </div>
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>
              </div>
          
              <div class="flex-center mg-t">
                <div style="width:92%;">
                  <el-input style="width:100%;" placeholder="请输入服务地址"  v-model="addFormsi.address"></el-input>
                </div>
              </div>
              <div class="flex-center mg-t">
                <div style="width:92%;">
                  <el-input style="width:100%;" placeholder="请输入手机号"  v-model="addFormsi.phone"></el-input>
                </div>
              </div>
              <div class="flex-center mg-t">
                <div class="p-r" style="width:92%;">
                  <el-input style="width:100%;" placeholder="请输入验证码"  v-model="addFormsi.code"></el-input>
                  <span v-if="!timecodei" class="btncc cl-all" @click="getYzmti">
                    获取验证码
                  </span>
                          <span v-if="timecodei" class="btncc cl-info"
                            >
                            {{numi}}秒重新获取
                            </span
                          >
                </div>
              </div>
              <div class="tx-center mg-t20">
                <el-button style="width:92%;font-size:16px;" type="primary" @click="addprojecti">立即预约</el-button>
              </div>
            </div>
            <div style="padding:7px 0;border-radius:0 0 8px 8px" class="bg-gray cl-info mg-t20 flex-center">
             <img
              style="width: 16px;margin-right:6px;"
              src="../assets/image/pho.png"
              alt=""
            /> 电话预约:4000-027-282
            </div>
          </div>
        </div>

        <div class="bg-gray" style="padding-bottom:40px;">
          <div class="ql">
            <div style="padding:30px 0 20px 0" class=" tx-center">
            <div class="tx-25 ft-600">维修服务</div>
            <div class="cl-info tx-16">安全、高质量</div>
          </div>
          <img
              style="width: 100%;"
              src="../assets/image/index1.png"
              alt=""
            />
            <div style="padding:30px 0 20px 0" class=" tx-center">
            <div class="tx-25 ft-600">热门服务</div>
            <div class="cl-info tx-16">专业服务 品质保证</div>
          </div>
          <div class="flex-bc mg-t" style="width:100%;">
            <div class="tio1">
              <div class="tx-20">管道疏通</div>
              <div class="mg-t6">疏通去味，通畅无阻</div>
              <div class="tx-center"><img
              style="width: 70%;"
              src="../assets/image/tip1.png"
              alt=""
            /></div>
              <div @click="addOrderp(5)" class="btty cu">立即预约</div>
            </div>
            <div class="tio2">
              <div class="tx-20">水电维修</div>
              <div class="mg-t6">高效服务，就近上门</div>
              <div class="tx-center"><img
              style="width: 70%;"
              src="../assets/image/tip2.png"
              alt=""
            /></div>
              <div @click="addOrderp(6)" class="btty cu">立即预约</div>
            </div>
            <div class="tio3">
              <div class="tx-20">家电维修</div>
              <div class="mg-t6">专业维修，售后无忧</div>
              <div class="tx-center"><img
              style="width: 70%;"
              src="../assets/image/tip3.png"
              alt=""
            /></div>
              <div @click="addOrderp(3)" class="btty cu">立即预约</div>
            </div>
            <div class="tio4">
              <div class="tx-20">家电清洗</div>
              <div class="mg-t6">专业仪器，焕然一新</div>
              <div class="tx-center"><img
              style="width: 70%;"
              src="../assets/image/tip4.png"
              alt=""
            /></div>
              <div @click="addOrderp(7)" class="btty cu">立即预约</div>
            </div>
            <div class="tio5">
              <div class="tx-20">防水补漏</div>
              <div class="mg-t6">精准定位，无处可漏</div>
              <div class="tx-center"><img
              style="width: 70%;"
              src="../assets/image/tip5.png"
              alt=""
            /></div>
              <div @click="addOrderp(8)" class="btty cu">立即预约</div>
            </div>
          </div>
         
          <div class="flex-c" style="justify-content: center;margin-top:6px;">
            <div @click="toAllQr" style="cursor: pointer" class="myttt cl-all mg-t20">
              全部服务 <img
              style="width: 16px;height:16px;"
              src="../assets/image/lj.png"
              alt=""
            />
            </div>
          </div>

          <div class="www">
           <div style="width:90%;">
              <el-carousel :interval="5000" type="card" height="160px">
              <el-carousel-item v-for="(item,index) in indexRlist" :key="index">
                <div class="box22">
                  <div class="flex-b tx-20">
                    <div>{{item.name}}</div>
                    <div>{{item.title}}</div>
                  </div>
                  <div class="tx-16 mg-t p-r" style="line-height:26px;">
                    <img
              style="width: 16px;height:16px;margin-right:6px;"
              src="../assets/image/gg.png"
              alt=""
            />
            {{item.review}}
            <img
              class="tyty"
              src="../assets/image/gg.png"
              alt=""
            />
            </div>
                </div>
              </el-carousel-item>
            </el-carousel>
           </div>
          </div>
          </div>
        </div>
        
      </div>
      <!-- 首页end... -->

      <!-- 维修标准 -->
      <div class="ql" style="padding-bottom:40px;" v-if="selectTab == 2">
        <div>
          <img
              style="width: 100%; display: block"
              src="../assets/image/smf.jpg"
              alt=""
            />
        </div>
        <div style="font-size:24px;padding:30px 0 20px 0" class="ft-600 tx-center">佳修匠的每一位维修工程师都经过严格的岗前培训与考核</div>
        <div class="flex-b">
          <div :class="showText == 1 ? 'aaa' : 'bbb'" @mouseenter="isShowTx(1)" @mouseleave="noShowTx()">
            <div class="tx-center" v-if="showText == 1">
              <div class="topu">身份核查</div>
              <div style="line-height:24px;">
                在每一位工程师入职时进行身份验证确保为您服务的人员信息真实可靠
              </div>
            </div>
            <div v-else>
              <img
              style="width: 40%;"
              src="../assets/image/t1.png"
              alt=""
            />
            <div>身份核查</div>
            </div>
          </div>
          <div  :class="showText == 2 ? 'aaa' : 'bbb'" @mouseenter="isShowTx(2)" @mouseleave="noShowTx()">
            <div class="tx-center" v-if="showText == 2">
              <div class="topu">技能考核</div>
              <div style="line-height:24px;">
                在每一位工程师工作前对他们的技能进行考核，保证维修质量
              </div>
            </div>
            <div v-else>
            <img
              style="width: 40%;"
              src="../assets/image/t2.png"
              alt=""
            />
            <div>技能考核</div>
          </div>
          </div>
          <div  :class="showText == 3 ? 'aaa' : 'bbb'" @mouseenter="isShowTx(3)" @mouseleave="noShowTx()">
            <div class="tx-center" v-if="showText == 3">
              <div class="topu">岗前培训</div>
              <div style="line-height:24px;">
                每一位工程师上岗前都会进行服务流程、价格体系和系统操作的培训
              </div>
            </div>
            <div v-else>
              <img
              style="width: 40%;"
              src="../assets/image/t3.jpg"
              alt=""
            />
            <div>岗前培训</div>
          </div>
          </div>
          <div  :class="showText == 4 ? 'aaa' : 'bbb'" @mouseenter="isShowTx(4)" @mouseleave="noShowTx()">
            <div class="tx-center" v-if="showText == 4">
              <div class="topu">系统督岗</div>
              <div style="line-height:24px;">
                工程师上门服务都会佩戴记录仪，系统进行监督确保服务质量和安全
              </div>
            </div>
            <div v-else>
            <img
              style="width: 40%;"
              src="../assets/image/t4.png"
              alt=""
            />
            <div>系统督岗</div>
            </div>
          </div>
          <div  :class="showText == 5 ? 'aaa' : 'bbb'" @mouseenter="isShowTx(5)" @mouseleave="noShowTx()">
            <div class="tx-center" v-if="showText == 5">
              <div class="topu">商业保险</div>
              <div style="line-height:24px;">
                平台为每一位工程师购买人身意外险。为每一次维修过程投保
              </div>
            </div>
            <div v-else>
            <img
              style="width: 40%;"
              src="../assets/image/t5.png"
              alt=""
            />
            <div>商业保险</div>
          </div>
          </div>
        </div>
          <div style="font-size:24px;padding:30px 0 20px 0" class="ft-600 tx-center">服务流程化</div>
          <div>
              <img
              style="width: 100%;"
              src="../assets/image/lc.jpg"
              alt=""
            />
          </div>
          <div class="flex-c" style="justify-content: center;margin-top:6px;">
            <div @click="addOrder" style="cursor: pointer" class="myttt cl-all">
              立即预约 <img
              style="width: 16px;height:16px;"
              src="../assets/image/lj.png"
              alt=""
            />
            </div>
          </div>
      </div>
      <!-- 维修标准end... -->

      <!-- 关于我们 -->
      <div class="ql" style="padding-bottom:40px;" v-if="selectTab == 4">
        <div>
          <img
              style="width: 100%; display: block"
              src="../assets/image/gywm.jpg"
              alt=""
            />
        </div>
        <div class="flex mg-t20">
          <div style="cursor: pointer" @click="selectYws(1)" :class="selectYw == 1 ? 'ywis' : 'ywi'">
            <div>公司简介</div>
            <div>
              <img
            v-if="selectYw == 1"
              style="width: 20px;transform: translate(0px, -2px);"
              src="../assets/image/yw.png"
              alt=""
            />
            </div>
          </div>
          <div  style="cursor: pointer" @click="selectYws(2)" :class="selectYw == 2 ? 'ywis' : 'ywi'">
            <div>企业文化</div>
            <div>
              <img
            v-if="selectYw == 2"
              style="width: 20px;transform: translate(0px, -2px);"
              src="../assets/image/yw.png"
              alt=""
            />
            </div>
            </div>
        </div>
        <div style="font-size:24px;padding:20px 0" class="ft-600">{{selectYw == 1 ? '公司简介' : '企业文化'}}</div>
        <div v-if="selectYw == 1" style="line-height:34px;">
          佳修匠是湖北海行科技有限公司旗下专业从事家庭维修的平台，公司总部设在湖北武汉硚口区，立足武汉面向全国。<br/> 
          公司主营范围：水电维修、管道疏通、家电维修、家电清洗、防水补漏、墙面翻新、开锁换锁、挂件安装、家具安装维修、台面抛光。<br/>
           公司致力于给每一个家庭提供安全、高品质的家庭维修服务，采用科技化的维修技术，注重维修过程的品质，全程为客户提供星级专业化服务，售前售中售后随时解决客户问题，让客户享受到极致的服务体验。
        </div>
         <div v-if="selectYw == 2" style="line-height:34px;">
           使命：<br/>  让每个家庭都能享受安全、高品质的家庭维修服务。<br/>  价值观： <br/> 正直诚信、家庭责任、服务心态、积极努力、合作共赢；<br/> 
            企业与员工共同成长；<br/>  一切以客户为中心。
        </div>
      </div>

      <!-- 全部服务 -->
      <div v-if="selectTab == 3">
        <!-- 项目详情 -->
        <div v-if="showIndex == 4" class="bg-gray" style="padding:30px 0;">
          <div class="tx-18 ql">
            <div class="bg-white flex-b">
              <div style="width:52%;display: inline-block;box-sizing: border-box;">
                <el-image
                  :preview-src-list="[$api + '/static/' + selectInfo.carousels[0].path]"
                  :src="$api + '/static/' + selectInfo.carousels[0].path"
                  style="width: 100%;"
                  alt=""
                >
                </el-image>
              </div>
              <div style="width:48%;display: inline-block;box-sizing: border-box;padding:24px;">
                <div class="tx-24">{{selectInfo.name}}</div>
                <div class="cl-info tx-16 mg-t">价格透明，不收上门费</div>
                <div class="flex mg-t cl-red">
                  <div>￥{{selectInfo.price}}元起</div>
                  <div></div>
                </div>
                <div class="flex-b mg-t border-bottom" style="padding-bottom:12px;" >
                  <div class="flex-c">
                    <img
                      style="width: 26px;"
                      src="../assets/image/dd.jpg"
                      alt=""
                    />服务保障
                  </div>
                  <div>
                    准时上门服务/标准报价/意外险
                  </div>
                </div>
                <div class="flex-bc mg-t20" style="width:100%">
                  <div style="width:30%;">
                    <img
                      style="width:100%;"
                      :src="'data:image/png;base64,' + ewm"
                      alt=""
                    />
                  </div>
                  <div style="width:30%;">
                    <div>手机下单更便捷</div>
                    <div>微信扫一扫</div>
                  </div>
                  <div style="width:30%;">
                    <div>4000-027-282</div>
                    <div>电话预约</div>
                  </div>
                </div>

                 <div class="tx-center mg-t">
                    <el-button style="width:100%;font-size:16px;" type="primary" @click="addOrderpq">在线下单</el-button>
                  </div>
              </div>
            </div>

            <div class="tx-18 bg-white mg-t20 flex">
            <!-- <div style="width:15%;padding:10px;box-sizing: border-box">
              微信扫码便捷下单
            </div> -->

            <div style="width:100%;box-sizing: border-box;">
              <div class="bg-gray tx-center border" style="padding:8px 0">服务收费</div>
              <el-table :data="projectSub" border style="margin-top:0;">
                <el-table-column
                  label="服务项目"
                  prop="name"
                ></el-table-column>
                <el-table-column
                  label="价格"
                  prop="price"
                >
                <template slot-scope="scope">
                  <div @click="showMinPr(scope.row)" class="flex-c">
                    {{scope.row.price}}/{{scope.row.unit}} <i v-if="scope.row.modelStatus == 0" class="el-icon-arrow-right cu"></i>
                  </div>
                </template>
                </el-table-column>
              </el-table>

              <div style="padding-left:16px;" class="mg-t">特殊说明</div>
              <div style="padding-left:16px;" class="mg-t6 cl-info">因型号、维修难度、品牌不同，最终价格仅供参考</div>

              <div class="bg-gray tx-center mg-t20 border" style="padding:8px 0">服务指南</div>
              <div style="padding-left:16px;" class="mg-t20">导航指南</div>
              <div class="mg-t tx-center">
                <img
                      style="width: 80%;"
                      src="../assets/image/fuzn.png"
                      alt=""
                    />
              </div>
              <div style="padding-left:16px;" class="mg-t">详情</div>
              <div style="padding-left:16px;" class="mg-t">
                <img
                      style="width: 30px;;"
                      src="../assets/image/xjx.png"
                      alt=""
                    />
              </div>
              
              <div class="tx-16 mg-t" style="line-height: 34px;padding-left:16px;">
                1.保修期：质保返修{{ selectInfo.repairDay }}天。
                <br />2.上门费：工程师上门后，如客户取消服务，需收取30元上门费，如正常完成付款，<span style="margin-left:0;" class="cl-all">不收上门费</span>。
                <br />3.我们采用价目表标准报价，为了保障您的权益，公司<span style="margin-left:0;" class="cl-all">严禁</span>工程师胡乱收费，如有发生请您联系客服进行投诉或评价说明。
                <br />4.为了保障您的<span style="margin-left:0;" class="cl-all">权益</span>和隐私，请使用在线下单和支付的方式，不要私下与工程师联系。
                <br />5.服务时段：8:00-20:00
              </div>

              <div class="bg-gray tx-center mg-t20 border" style="padding:8px 0">服务评价</div>
              <div v-for="(item,index) in reviewList" :key="index" class="border-bottom" style="margin:0 80px;">
                <div class="flex">
                  <div style="width:9%;box-sizing: border-box;padding:20px 0 20px 20px;">
                    <el-image
                      v-if="item.photo"
                      :preview-src-list="[$api + '/static/' + item.photo]"
                      :src="$api + '/static/' + item.photo"
                      style="width: 80%;"
                      alt=""
                    >
                    </el-image>
                    <img
                      v-else
                        style="width:80%;"
                        src="../assets/image/nm.png"
                        alt=""
                      />
                  </div>
                  <div style="width:91%; box-sizing: border-box;padding:20px;">
                    <div class="flex-bc" style="width:100%;">
                      <div>{{item.nickName}}</div>
                      <div>{{item.createTime}}</div>
                    </div>
                    <div class="mg-t">
                      <el-rate v-model="item.stars" :disabled="true"></el-rate>
                    </div>
                    <div v-if="item.remark" class="mg-t">
                      {{item.remark}}
                    </div>
                    <div class="mg-t" v-if="item.images.length > 0">
                      <el-image
                        v-for="item in item.images"
                        fit="cover"
                        :key="item"
                        style="width: 80px; height: 80px; margin-right: 10px"
                        :src=" $api + '/static/' + item"
                        :preview-src-list="[ $api + '/static/' + item]"
                      ></el-image>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mg-t tx-15 flex-end" style="padding:0 80px 20px 80px;align-items: center;">
                <div @click="handleCurrentChange(-1)" class="border cu" style="border-radius:6px;padding:6px;align-items: center;"><i class="el-icon-arrow-left"></i>上一页</div>
                <div class="border" style="margin:0 20px;border-radius:6px;padding:6px;">当前第{{pageInfo.pageNum}}页</div>
                <div @click="handleCurrentChange(1)" class="border cu" style="border-radius:6px;padding:6px;align-items: center;">下一页 <i class="el-icon-arrow-right"></i></div>        
              </div>
            </div>

          </div>
          </div>
        </div>
        <!-- 项目详情end。。。 -->

        <div v-if="showIndex == 0" class="flex ql tx-18 p-r">
          <div v-if="showIndex < 10" class="box1">
            <div >
              <div
                :class="item.typeId == showType.typeId ? 'item cu bg-all' : 'item cu'"
                @mouseenter="showTy(item)" 
                @mouseleave="noshowTy()"
                :index="item.typeId"
                v-for="(item, index) in tabList"
                :key="item.index"
              >
              <div @click="selectItem(item, index)">{{ item.typeName }}</div>
                <div class="tyt" v-if="showType.typeId == item.typeId">
                  <div>{{showType.typeName}}</div>
                  <div class="flex tx-15" style="flex-wrap:wrap;line-height:40px;"> 
                    <div @click.stop="selectPro(item,items)" style="margin-right:30px;" :class="showTyin === indexs ? 'cl-all' : ''" v-for="(items,indexs) in showType.projects" @mouseenter="showTyi(indexs)" @mouseleave="noshowTyi()" :key="indexs">{{items.name}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box2">
            <div v-if="showIndex == 0">
              <img
                style="width: 100%; display: block"
                src="../assets/image/webbander12.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="home-container" style="background:#F6F6F6;padding-bottom:40px;">
          <div class="ql">
            <div v-if="showIndex == 0" class="tx-18 tab">
        <div
          class="tabitem bg-white p-r"
          v-for="(item, index) in tabList"
          :index="item.typeId"
          :key="item.typeId"
        >
          <div class="flex-bc itmtop">
            <div>{{ item.typeName }}</div>
                <el-image
                :preview-src-list="[$api + '/static/' + item.typeImage]"
                :src="$api + '/static/' + item.typeImage"
                style="width: 90px; height: 90px;transform: translate(0px, 14px);"
                alt=""
              >
              </el-image>
          </div>
          <div class="tcc">
            <div v-for="(items,indexs) in item.projects" v-if="indexs < 9" :index="items.id" :key="indexs" @click.stop="selectPro(item,items)" class="tx-14 mg-t6 cuu">
                {{ items.name }}
            </div>
          </div>
          <div class="mores">
            <div @click="selectItem(item, index)" class="cu tx-14 hidden-text cl-info">
              更多<i style="font-size:16px;" class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- 推荐项目 -->
      <div v-if="selectTab == 3 && showIndex == 0">
        <div class="tx-center" style="margin-top:30px;">
          <div class="tx-20 ft-600">为您推荐</div>
          <div class="tx-14">安全、高质量</div>
        </div>

        <div class="flex-bc mg-t20">
          <div @click="selectPro(mpList[0],mpList[0].item)" style="width:32%;    text-align: center;">
            <el-image
                  :src="require('../assets/image/ftt1.jpg')"
                  style="width:100%;"
                  alt=""
                >
                </el-image>
                <div class="mg-t6">电路开关安装维修</div>
          </div>
          <div @click="selectPro(mpList[1],mpList[1].item)" style="width:32%;    text-align: center;">
            <el-image
                  :src="require('../assets/image/ftt2.jpg')"
                  style="width:100%;"
                  alt=""
                >
                </el-image>
                <div class="mg-t6">灯具安装维修</div>
          </div>
          <div @click="selectPro(mpList[2],mpList[2].item)" style="width:32%;    text-align: center;">
            <el-image
                  :src="require('../assets/image/ftt3.jpg')"
                  style="width:100%;"
                  alt=""
                >
                </el-image>
                <div class="mg-t6">洗菜盆疏通</div>
          </div>
        </div>
      </div>
          </div>
        </div>

      <!-- 全部项目的所有tab -->
      <div class="ql">
        <div v-if="showIndex == 3" style="margin-top:26px;">
          <div class="flex tx-15" style="align-items: baseline;">
            <div class="ft-600 tx-16" style="width:100px;">服务分类</div>
            <div class="flex-c" style="flex-wrap:wrap;width:80%;">
              <div style="margin-right:24px; margin-top:10px;cursor: pointer;" v-for="(item,index) in tabList" :key="index" :class="selectIndex == index ? 'cl-all' : ''">
                <div @click="selectItem(item, index)">{{item.typeName}}</div>
              </div>
            </div>
          </div>
          <div class="flex mg-t20 tx-15" style="align-items: baseline;">
            <div class="ft-600 tx-16" style="width:100px;">产品分类</div>
            <div class="flex-c" style="flex-wrap:wrap;width:80%;">
              <div @click="showInfo(itemy)" style="margin-right:24px;margin-top:10px;cursor: pointer;" v-for="(itemy,indexy) in typeItem.projects" :key="indexy">{{itemy.name}}</div>
            </div>
          </div>
        </div>

        <!-- 信息列表 -->
        <div
          v-if="showIndex == 3"
          class="flex-c"
          style="margin-top: 30px;flex-wrap: wrap;"
        >
          <div
            class="tx-18 bg-white"
            style="border: 1px solid #d4d4d4; width:240px;margin-right:24px;margin-top:24px;padding-bottom:20px;border-radius:8px;"
            v-for="item in projectList"
            :index="item.id"
            :key="item.id"
            @click="showInfo(item)"
          >
            <el-image
              :src="$api + '/static/' + item.image"
              style="width: 100%;border-radius:8px;"
              alt=""
            >
            </el-image>
            <div class="tx-center">
                <div class="ft-600 tx-18">{{ item.name }}  </div>
                <div class="cl-info tx-14 mg-t6">价格透明·不收上门费</div>
                <div class="cl-red mg-t6">￥{{ item.price }}起</div>
            </div>
          </div>

          <div
            v-if="projectList.length == 0"
            class="pd cl-info flex-center tx-18"
            style="padding-bottom: 400px"
          >
            暂无数据~
          </div>
        </div>
      </div>

      <!-- 分类项目列表 -->
      <div v-if="showIndex == 1" style="margin-top: 30px">
        <div
          class="flex-c tx-18 mg-t mg-b"
          style="border-top: 1px solid #d4d4d4"
          v-for="item in projectList"
          :index="item.id"
          :key="item.id"
        >
          <el-image
            :preview-src-list="[item.image]"
            :src="item.image"
            style="width: 140px; height: 140px"
            alt=""
          >
          </el-image>
          <div
            @click="toInfo(item)"
            class="mg-l cu"
            style="padding-right: 600px"
          >
            <div>{{ item.name }}  </div>
            <div class="mg-t20 cl-red">￥{{ item.price }}</div>
          </div>
        </div>
      </div>
      </div>
      
      <el-dialog
            :close-on-click-modal="false"
            title=""
            :visible.sync="showEwm"
            width="40%"
            @close="closeEwm"
          >
            <div class="pd flex-center">
              <div
                style="padding-right: 50px"
                v-if="showEwm && !isPay"
                id="qrcode"
              ></div>
              <div v-if="isPay">
                <div class="tx-18 ft-600 tx-center">支付完成</div>
                <div class="tx-16 tx-center mg-t">
                  电话：{{ selectInfo.phone }}
                </div>
              </div>
              <div v-else>
                <div class="tx-18 ft-600 tx-center">查看电话</div>
                <div class="tx-16 tx-center mg-t">查看电话需支付一元</div>
              </div>
            </div>
          </el-dialog>

      <!-- 发布信息 -->
      <div
        v-if="showIndex == 5"
        style="margin-top: 30px; border-top: 1px solid #d4d4d4"
      >
        <div class="mg-t20">
          <el-form
            :model="postForm"
            :rules="postFormRules"
            ref="postFormRef"
            label-width="130px"
          >
            <el-form-item label="服务类型：" prop="typeId">
              <el-select
                class="input"
                clearable
                v-model="postForm.typeId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in pproject"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标题：" prop="title">
              <el-input
                class="input"
                placeholder="请输入维修需求"
                v-model="postForm.title"
              ></el-input>
            </el-form-item>
            <el-form-item label="期望价格：" prop="price">
              <el-input
                class="input"
                type="number"
                placeholder="请输入价格"
                v-model="postForm.price"
              ></el-input>
            </el-form-item>
            <el-form-item label="详情描述：" prop="remark">
              <el-input
                class="input"
                placeholder="请输入需要维修的详细信息"
                v-model="postForm.remark"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传图片：" prop="images">
              <el-upload
                action=""
                :on-change="onchange"
                :show-file-list="false"
                :http-request="httpRequeste"
              >
                <img
                  v-if="imageUrl"
                  :src="imageUrl"
                  :alt="imageUrl.name"
                  class="avatar"
                  width="200"
                  height="200"
                />
                <el-button v-else type="primary" size="small">
                  选择图片
                </el-button>
              </el-upload>
            </el-form-item>
            <el-form-item label="姓名：" prop="name">
              <el-input
                placeholder="请输入"
                class="input"
                v-model="postForm.name"
              ></el-input>
            </el-form-item>
            <el-form-item label="电话：" prop="phone">
              <el-input
                placeholder="请输入"
                class="input"
                v-model="postForm.phone"
              ></el-input>
            </el-form-item>
            <el-form-item label="地址：" prop="address">
              <el-input
                placeholder="请输入"
                class="input"
                v-model="postForm.address"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button
            style="width: 200px; margin: 30px 0 40px 130px"
            type="primary"
            @click="postInfo"
            >立即发布</el-button
          >
        </div>
      </div>
    </div>
    <!-- 内容区域 -->

    <!-- 底部 -->
    <div class="bg-all">
      <div class="fotter-container"> 
        <div class="flex-b bttt">
          <div class="fit">
             <img style="height: 100px;" src="../assets/image/ft1.jpg" alt="" />
          </div>
          <div class="fit">
             <img style="height: 100px;" src="../assets/image/ft2.jpg" alt="" />
          </div>
          <div class="fit">
             <img style="height: 100px;" src="../assets/image/ft3.jpg" alt="" />
          </div>
        </div>
         <!-- <div style="color:#ffffff;display: flex;align-items: center;justify-content: center;margin-bottom:26px;">
           <div style="width:150px;text-algin:center;">服务流程</div>
           <div style="width:150px;text-algin:center;">全部服务</div>
           <div style="width:150px;text-algin:center;">关于我们</div>
         </div> -->
         <div style="color:#ffffff;display: flex;align-items: center;justify-content: center;">
           <div >湖北海行科技有限公司</div>
          <a style="color:#ffffff;" class="mg-l20" href="https://beian.miit.gov.cn" target="_blank"
            >鄂ICP备2021013706号-1</a
          >
          <img
            style="width: 20px; height: 19px; margin-left: 16px"
            src="../assets/image/gongan.png"
            alt=""
          />
          <a
            target="_blank"
            style="color:#ffffff;"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010402001444"
            >鄂公网安备 42010402001444号</a
          >
          <span style="margin-left:8px;">增值电信业务经营许可证：鄂B2-20220160</span>
      </div>
         </div>
    </div>

         <el-dialog
            :close-on-click-modal="false"
            title=""
            :visible.sync="showInfoDio"
            width="60%"
            @close="showInfoDio = false"
          >
          <div class="tx-18 ft-600" style="padding:10px 0">{{minPrInfo.name}}</div>
            <el-table :data="minPrInfo.list" border style="margin-top:0;">
                <el-table-column
                  label="项目型号"
                  prop="name"
                ></el-table-column>
                <el-table-column
                  label="价格"
                  prop="price"
                  width="120px;"
                >
                <template slot-scope="scope">
                  <div @click="showMinPr(scope.row)" class="flex-c">
                    {{scope.row.price}}/{{scope.row.unit}} <i v-if="scope.row.modelStatus == 0" class="el-icon-arrow-right cu"></i>
                  </div>
                </template>
                </el-table-column>
                <el-table-column
                  label="可议价"
                  prop="price"
                  width="120px;"
                >
                <template slot-scope="scope">
                  <div class="flex-c">
                    {{scope.row.negotiateStatus == 0 ? '是' : '否'}}
                  </div>
                </template>
                </el-table-column>
                </el-table>
   
                <span slot="footer" class="dialog-footer">
                    <el-button round style="width:100px;font-size:16px;" @click="showInfoDio = false">关闭</el-button>
                </span>
              </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title=""
      :visible.sync="addSuccess"
      width="50%"
      @close="addSuccess = false"
    >
      <!--内容主体区域-->
      <div class="tx-25 tx-center">预约成功</div>
      <div class="flex-center mg-t">
        <el-image
          :src="require('../assets/image/jjj.png')"
          style="width: 40px;"
          alt=""
        >
        </el-image>
      </div>
      <div class="flex-center mg-t">
        您已预约成功，客服人员将在稍后与您联系，请保持电话畅通。
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { Stream } from 'stream';
export default {
  data() {
    return {
      tabList: [],
      cardList: [
        {name: '首页', tab: 1}
      ],
      isPay: false,
      showEwm: false,
      tabLists: [],
      selectIndex: 0,
      selectIndexP: '',
      menulist: [],
      queryInfo: {
        name: '',
      },
      userName: '',
      loginForm: {
        username: '',
        password: '',
        code: '',
      },
      postForm: {
        address: '',
        images: '',
        title: '',
        price: '',
        name: '',
        phone: '',
        typeId: '',
        remark: '',
      },
      hotList: [],
      postFormRules: {
        name: [
          {
            required: true,
            message: '请输入2-6位姓名',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 6,
            message: '请输入2-6位姓名',
            trigger: 'blur',
          },
        ],
        title: [
          {
            required: true,
            message: '请输入维修需求',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 250,
            message: '请输入250字以内的需求',
            trigger: 'blur',
          },
        ],
        price: [
          {
            required: true,
            message: '请输入期望价格',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 10,
            message: '请输入期望价格',
            trigger: 'blur',
          },
        ],
        images: [
          {
            required: true,
            message: '请选择维修图片',
            trigger: 'blur',
          },
        ],
        typeId: [
          {
            required: true,
            message: '请选择服务类型',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 100,
            message: '请选择服务类型',
            trigger: 'blur',
          },
        ],
        address: [
          {
            required: true,
            message: '请输入详细地址',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 250,
            message: '请输入2-250字以内详细地址',
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            message: '请输入手机号码',
            trigger: 'blur',
          },
          {
            min: 11,
            max: 11,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
        remark: [
          { required: true, message: '请输入详情描述', trigger: 'blur' },
          {
            min: 2,
            max: 250,
            message: '请输入2-250字以内的详情描述',
            trigger: 'blur',
          },
        ],
      },
      myTime: '',
      myTimei: '',
      timecodei: false,
      showType: '',
      total: 0,
      mpList:[
        {
          item: {
            id: "13",
            image: "upload/projectCarousel/701166400412061696.png",
            name: "电路开关安装、维修",
            price: "30.00",
            remark: "",
            repairDay: "30"
          },
          typeId: "6",
          typeImage: "upload/projecType/690910997266829312.png",
          typeName: "水电维修"
        },
        {
          item: {
            id: "14",
            image: "upload/projectCarousel/647466581219086336.png",
            name: "灯具安装、维修",
            price: "30.00",
            remark: "",
            repairDay: "30"
          },
          typeId: "6",
          typeImage: "upload/projecType/690910997266829312.png",
          typeName: "水电维修"
        },
        {
          item: {
            id: "21",
            image: "upload/projectCarousel/647467320104456192.png",
            name: "洗菜盆疏通",
            price: "30.00",
            remark: "",
            repairDay: "1"
          },
          typeId: "5",
          typeImage: "upload/projecType/690911043236401152.png",
          typeName: "管道疏通"
        }
      ],
      //  添加表单的验证规则对象
      addFormRules: {
        username: [
          {
            required: true,
            message: '请输入6-18位英文数字组成的账号',
            trigger: 'blur',
          },
          {
            min: 6,
            max: 18,
            message: '请输入6-18位英文数字组成的账号',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入8-18位英文数字组成的密码',
            trigger: 'blur',
          },
          {
            min: 8,
            max: 18,
            message: '请输入8-18位英文数字组成的密码',
            trigger: 'blur',
          },
        ],
        passwords: [
          {
            required: true,
            message: '请输入8位英文数字组成的密码',
            trigger: 'blur',
          },
          {
            min: 8,
            max: 8,
            message: '请输入8位英文数字组成的密码',
            trigger: 'blur',
          },
        ],
        code: [
          { required: true, message: '请输入6位验证码', trigger: 'blur' },
          {
            min: 6,
            max: 6,
            message: '请输入6位验证码',
            trigger: 'blur',
          },
        ],
      },
      timecode: '',
      imecodei: '',
      ewm: '',
      orderNum: 0,
      selectTab: 1,
      showXiYi: false,
      time: null,
      pproject: [],
      projectList: [],
      code: '',
      showedit: false,
      showIndex: 0,
      iconsObj: {
        125: 'iconfont icon-user',
        103: 'iconfont icon-tijikongjian',
        101: 'iconfont icon-shangpin',
        102: 'iconfont icon-danju',
        145: 'iconfont icon-baobiao',
      },
      isCollapse: false,
      activePath: '',
      showNum: false,
      imageUrl: '',
      showText: 0,
      moveItem: 0,
      addDialogVisible: false,
      addSuccess: false,
      num: 60,
      numi: 60,
      addForms: {
        "address": "",
        "typeId" : '',
        "area": "",
        "code": "",
        "phone": "",
        "projectId": ''
      },

         addFormsi: {
        "address": "",
        "typeId" : '',
        "area": "",
        "code": "",
        "phone": "",
        "projectId": ''
      },

      //  添加表单的验证规则对象
      addFormRuless: {
        name: [
          {
            required: true,
            message: '请输入2-30个字的项目名',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 30,
            message: '请输入2-30个字的项目名',
            trigger: 'blur',
          },
        ],
        shortName: [
          {
            required: true,
            message: '请输入项目简称',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 30,
            message: '请输入2-20个字的项目简称',
            trigger: 'blur',
          },
        ],
        repairDay: [
          { required: true, message: '请输入维修期', trigger: 'blur' },
          {
            min: 1,
            max: 1000,
            message: '请输入维修期',
            trigger: 'blur',
          },
        ],
        sort: [
          {
            required: true,
            message: '请输入1-100的排序，数字越小排位越靠前',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 3,
            message: '请输入1-100的排序，数字越小排位越靠前',
            trigger: 'blur',
          },
        ],
        price: [
          { required: true, message: '请输入1-1000的价格', trigger: 'blur' },
          {
            min: 1,
            max: 1000,
            message: '请输入1-1000的价格',
            trigger: 'blur',
          },
        ],
        remark: [
          { required: false, message: '请输入用户备注', trigger: 'blur' },
          {
            min: 0,
            max: 40,
            message: '备注的长度在0~40个字符之间',
            trigger: 'blur',
          },
        ],
      },
      porList:[],
      selectYw: 1,
      showTyin: '',
      typeItem: '',
      reviewList: [],
      showTyinq: '',
      pageInfo: {
        pageNum:0,
        pageSize: 10,
        projectId: ''
      },
      minPrInfo: '',
      showInfoDio: false,
      showTypeq: false,
      projectSub: [],
      showAllT: false,
      showAllTq: false,
      showTypeqq: '',
      showTyinqq: '',
      indexNameq: '',
      selectInfo: '',
      indexRlist: [
       { name:'陈*亮', title: '电路开关安装维修',review:'吃晚饭的时候家里突然跳匝了，师傅来的很快，收费也合理。'},
       { name:'王*雅', title: '灯具安装维修',review:'在网上买了个吸顶灯，对比了几家感觉这个比较划算，师傅来了也很热心还帮我带走了垃圾。'},
       { name:'许*', title: '龙头安装维修',review:'龙头漏水好几天了，想起来换一下师傅来得还挺快的，叫帮忙给我带个龙头，带的龙头也是好龙头。'},
       { name:'刘*', title: '马桶疏通',review:'小孩的玩具掉进了马桶，师傅来了马上就解决了，还给马桶擦得干干净净，收费也不贵感觉还不错。'},
       { name:'方*诗', title: '空调清洗',review:'特价活动洗的没想到服务这么好，蒸汽洗的感觉很干净，洗出来很多脏水。'},
      ],
    }
  },
  created() {
    this.getProject();
  },
  methods: {
    isShowTx(num) {
      this.showText = num
    },

    noShowTx() {
      this.showText = 0
    },

    handleCurrentChange(num) {
      if(num == 1) { //下一页
        if(this.reviewList.length == 10) {
          this.pageInfo.pageNum = this.pageInfo.pageNum + 1
        }else {
          return this.$message.error('没有更多数据啦！')
        }
      }else { // 上一页
        if(this.pageInfo.pageNum == 1) return this.$message.error('没有上一页啦！')
        this.pageInfo.pageNum = this.pageInfo.pageNum - 1
      }
      this.getReview()
    },

    closeEwm() {
      this.showEwm = false
      clearInterval(this.time)
    },

    toInfo(item) {
      this.selectGood = item
      this.showIndex = 2 // 2是显示项目详情
    },

    showMinPr(row) {
      if(row.modelStatus == 1) return;
      this.$http.get(this.$api + '/pc/projectSubModel/' + row.id).then((res1) => {
        let obj = row;
        obj.list = res1.data
        this.minPrInfo = obj
        this.showInfoDio = true;
        })
    },

    showInfos(item, index) {
      item.myphone = item.phone.substr(0, 3) + '***' + item.phone.substr(7, 10)
      this.selectInfo = item
      this.selectIndex = index + 1
      this.showIndex = 4 // 2是显示项目详情
    },

    showTy(item) {
      console.log(item,'ooo0')
      if(this.showType == '') {
        this.showType = item
      } else {
        if(this.showType.typeId === item .typeId) return;
        this.showType = item
      }
    },

    noshowTy() {
      this.showType = ''
    },

    showTyq(item) {
      console.log(item,'item...')
      if(this.showTypeq == '') {
        this.showTypeq = item
      } else {
        if(this.showTypeq.typeId === item .typeId) return;
        this.showTypeq = item
      }
    },

    noshowTyq() {
      this.showTypeq = ''
    },

    showTyqq(item) {
      if(this.showTypeqq == '') {
        this.showTypeqq = item
      } else {
        if(this.showTypeqq.typeId === item .typeId) return;
        this.showTypeqq = item
      }
    },

    noshowTyqq() {
      this.showTypeqq = ''
    },

    showTyi(num) {
      this.showTyin = num;
    },

    noshowTyi() {
      this.showTyin = '';
    },

    showTyiq(num) {
      this.showTyinq = num;
    },

    noshowTyiq() {
      this.showTyin = '';
    },

    showTyiqq(num) {
      this.showTyinqq = num;
    },

    noshowTyiqq() {
      this.showTyinq = '';
    },

    toInfos(item, index) {
      this.selectGood = item
      this.selectIndex = index + 1
      this.showIndex = 2 // 2是显示项目详情
    },
    showInfo(item) { // 点击项目详情
      this.$http.get(this.$api + '/pc/project/' + item.id).then((res) => {
        this.selectInfo = res.data
        this.cardList = [...this.cardList,{name:item.name, tab: 3, index: 4}]
        this.$http.get(this.$api + '/pc/projectSub/' + res.data.id).then((res1) => {
          this.projectSub = res1.data
        })
        this.getEwm(res.data.id);
        this.pageInfo = {
        pageNum:1,
        pageSize: 10,
        projectId: res.data.id
      }
        this.getReview();
      })
      this.showIndex = 4 // 2是显示项目详情
    },

    getEwm(id) {
      let obj = {
          "content": "https://customer-test.haixing-kj.com/goods?id=" + id,
          "height": 300,
          "weight": 300
      }
      this.$http.post(this.$api + '/pc/qrCode', obj).then((res) => {
        this.ewm = res.data
        console.log(res,'99999999')
      })
    },

    selectProqq(items,item) {
      this.addForms.projectId = item.id
      this.showTypeqq = ''
      this.addForms.typeId = items.id
      this.showAllTq = false;
      this.showTyinqq = '';
      this.indexNameq = item.name
    },

    selectProq(items,item) {
      this.addFormsi.projectId = item.id
      this.showTypeq = ''
      this.addFormsi.typeId = items.id
      this.showAllT = false;
      this.showTyinq = '';
      this.indexName = item.name
    },

    selectPro(items,item) { // 点击项目
      this.cardList =  [{name:'全部服务', tab: 3, index: 0},{name:items.typeName, tab: 3, index: 3}]
      this.projectList = items.projects
      this.typeItem = items
      this.noshowTy()
      this.noshowTyi()
      this.toTop();
      this.showInfo(item)
    },

    selectItem(item, index) { // 点击类别
        this.cardList = [{name:'全部服务', tab: 3, index: 0},{name:item.typeName, tab: 3, index: 3}]
        this.projectList = item.projects
        this.showIndex = 3
        this.typeItem = item
        this.selectIndex = index
        this.toTop();
    },

    selectTabs(num) {
      this.showIndex = ''
      this.selectTab = num;
      this.cardList = [];
      if(num == 3) { // 点击了全部服务
        this.getProject();
      }
    },

    changeType() {
     for(let item of this.tabList) {
       if(item.typeId == this.addForms.typeId) {
         this.porList = item.projects
         return;
       }
     }
    },

    getReview() {
      this.$http.get(this.$api + '/pc/projectReview/'+ this.pageInfo.projectId , {params:this.pageInfo}).then((res) => {
        res.data.forEach((item) => {
          if(item.nickName != '匿名') {
            item.nickName =
              item.nickName.substr(0, 1) + '***' + item.nickName.substr(item.nickName.length-1, 1)
          }
          })
        this.reviewList = res.data;
      })
    },

     getProject() {
      this.$http.get(this.$api + '/pc/projectType').then((res) => {
        this.tabList = res.data
        this.tabLists = [{ typeName: '首页', typeId: 0 }, ...res.data]
        // 推荐项目处理 tid = 6 id = 13 ,14, tid = 5 id =21
        let liste = [];
        for(let item of res.data) {
          if(item.typeId == 6 || item.typeId == 5) {
            for(let items of item.projects) {
              if(items.id == 13 || item.id == 14 || item.id == 21) {
                liste.push(items)
              }
            }
          }
        }
        this.hotList = liste;
      })
    },

    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    },

    onchange(file, fileList) {
      var _this = this
      var event = event || window.event
      var file = event.target.files[0]
      var reader = new FileReader()
      //转base64
      reader.onload = function (e) {
        _this.imageUrl = e.target.result //将图片路径赋值给src
      }
      reader.readAsDataURL(file)
    },

    httpRequeste(resource) {
      let pic = resource.file
      const isLt2M = pic.size / 1024 < 150
      if (!isLt2M) {
        setTimeout(() => {
          this.imageUrl = ''
        }, 1000)
        return this.$message.error('上传图片大小不能超过150K')
      }
      let fd = new FormData()
      fd.append('images', pic)
      this.postForm.images = fd
    },

    showXy() {
      // 显示用户协议
      this.showXiYi = true
    },

    toTop() {
      document.documentElement.scrollTop = 0;
    },

    toAllQr() {
      this.toTop();
      this.selectTabs(3)
    },

    lookPhone() {
      this.$http
        .post(this.$api + '/pay', {
          id: this.selectInfo.id,
        })
        .then((res) => {
          this.isPay = false
          this.showEwm = true
          if (res.data.status == 0) {
            // 已经支付过
            this.isPay = true
          } else {
            setTimeout(() => {
              let qrcode = new QRCode('qrcode', {
                width: 200,
                height: 200, // 高度
                text: res.data.codeUrl, // 二维码内容
                // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
                // background: '#f0f'
                // foreground: '#ff0'
              })
            }, 200)
            this.time = setInterval(() => {
              this.getSta()
            }, 3000)
          }
        })
        .catch((err) => {
          if (err.status == 401) {
            // 未登录
            this.showIndex = 9
            this.selectIndex = -1
          }
        })
    },

    moveItems(num) { // 鼠标进入右侧悬浮项
      this.moveItem = num;
    },

    liveItems() { // 鼠标移除右侧悬浮项
      this.moveItem = 0
    },

    getSta() {
      // 获取支付状态
      this.$http
        .post(this.$api + '/status', {
          id: this.selectInfo.id,
        })
        .then((res) => {
          if (res.data == 0) {
            // 已支付,清楚定时器,显示号码
            clearInterval(this.time)
            this.isPay = true
          } else {
            console.log('未支付')
          }
        })
    },

    selectXi() {
      this.getXi()
      this.showIndex = 3
      this.selectIndex = this.tabLists.length - 1
    },

    getXi(id) {
      this.$http
        .get(this.$api + '/info', {
          params: { pageNum: 1, pageSize: 1000, typeId: id },
        })
        .then((res) => {
          res.data.forEach((item) => {
            item.myphone =
              item.phone.substr(0, 3) + '***' + item.phone.substr(7, 10)
          })
          this.projectList = res.data
        })
    },
    addDialogClosed() {
      this.addForms = {
        "address": "",
        "typeId" : '',
        "area": "",
        "code": "",
        "phone": "",
        "projectId": ''
      }
    },
      addprojecti() {
        if (!this.addFormsi.projectId) return this.$message.error('请选择项目！')
        if (!this.addFormsi.address)
          return this.$message.error('请输入服务地址！')
        if (!this.addFormsi.phone)
          return this.$message.error('请输入手机号码！')
        if (!this.addFormsi.code)
          return this.$message.error('请输入验证码！')
        this.$http.post(this.$api + '/pc/order', this.addFormsi).then((res) => {
          if (res.status !== 200) {
            return this.$message.error('预约失败！')
          }
          clearInterval(this.myTimei)
          this.timecodei = false;
          this.numi = 60;
          this.addDialogVisible = false
          this.addSuccess= true
          this.addFormsi = {
            "address": "",
            "typeId" : '',
            "area": "",
            "code": "",
            "phone": "",
            "projectId": ''
          }
        })
    },
      addproject() {
        if (!this.addForms.projectId) return this.$message.error('请选择项目！')
        if (!this.addForms.address)
          return this.$message.error('请输入服务地址！')
        if (!this.addForms.phone)
          return this.$message.error('请输入手机号码！')
        if (!this.addForms.code)
          return this.$message.error('请输入验证码！')
        this.$http.post(this.$api + '/pc/order', this.addForms).then((res) => {
          if (res.status !== 200) {
            return this.$message.error('预约失败！')
          }
          clearInterval(this.myTime)
          this.timecode = false;
          this.num = 60;
          this.addDialogVisible = false
          this.addSuccess= true
          this.addDialogClosed();
        })
    },
    addOrderpq() {
      this.addForms.typeId = this.selectInfo.typeId
      this.changeType();
      this.addForms.projectId = this.selectInfo.id
      this.indexNameq = this.selectInfo.name
      this.addDialogVisible = true;
    },
    addOrderp(pid) {
      for(let i = 0; i < this.tabList.length; i++) {
        if(this.tabList[i].typeId == pid) {
          this.selectTab = 3
          return this.selectItem(this.tabList[i], i)
        }
      }
    },
    addOrder() { // 预约下单
      this.indexNameq = '';
      this.showTypeqq = '';
      this.showTyinqq = '';
      this.showAllTq = false;
      this.addDialogVisible = true;
    },
    login() {
      // 登录
      this.$refs.loginForm.validate(async (valid) => {
        if (!valid) return
        if (this.loginForm.code != this.code)
          return this.$message.error('验证码错误，请重新输入！')
        this.$http
          .post(this.$api + '/login', {
            password: this.loginForm.password,
            username: this.loginForm.username,
          })
          .then((res) => {
            if (res.data) {
              this.$message.success('登录成功！')
              this.userName = res.data
              this.showIndex = 0 //去首页
            }
          })
      })
    },
    addXi() {
      // 点击发布信息
      this.getType()
      this.selectIndex = -1
      this.showIndex = 5
    },
    postInfo() {
      console.log(this.postForm)
      this.$refs.postFormRef.validate(async (valid) => {
        if (!valid) return
        // 先上传图片，在提交
        this.$http
          .post(this.$api + '/info/images', this.postForm.images)
          .then((res) => {
            this.postForm.image = res.data[0]
            this.$http.post(this.$api + '/info', this.postForm).then((res) => {
              if (res.data) {
                this.$message.success('发布成功！')
                this.postForm = {
                  address: '',
                  images: '',
                  name: '',
                  title: '',
                  price: '',
                  phone: '',
                  typeId: '',
                  remark: '',
                }
                this.imageUrl = ''
              }
            })
          })
      })
    },

    clickBot(num) {
      this.showIndex = num
      this.selectIndex = -1
      scrollTo(0, 0)
    },

    clickCard(item) {
      return;
      if(item.tab == 1) { // 首页 
        this.selectTab = 1
        this.cardList = [];
      }else if(item.tab == 2) { // 维修标准
        this.selectTab = 2
      }else if(item.tab == 3) { // 全部服务
        this.showIndex = 0;
        this.selectTab = 3
        this.cardList = [];
      }
    },

    search() {
      if (this.queryInfo.name == '')
        return this.$message.error('请输入搜索内容！')
      // 点击搜索
      this.selectIndex = -1
      this.$http
        .get(this.$api + '/pc/search?name=' + this.queryInfo.name)
        .then((res) => {
          // res.data.forEach((item) => {
          //   item.myphone =
          //     item.phone.substr(0, 3) + '***' + item.phone.substr(7, 10)
          // })
          this.cardList = [{name: '首页', tab: 1},{name:'全部服务', tab: 3, index: 0},{name:this.queryInfo.name}]
          this.projectList = res.data
          this.selectTab = 3
          this.showIndex = 3
        })
    },

    selectYws(num) {
      this.selectYw = num
    },

    toorder() {
      this.showNum = false
      window.sessionStorage.setItem('activePath', '/waitorder')
      this.activePath = '/waitorder'
      this.$router.push('/waitorder')
    },

    editPdw() {
      // 修改密码
      this.showedit = true
    },

    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    saveNavState(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },
    getType() {
      this.$http.get(this.$api + '/dropdown/type').then((res) => {
        this.pproject = res.data
      })
    },
    getYzmti() {
      // 获取验证码
      if (this.addFormsi.phone == '')
        return this.$message.error('请填写手机号！')
      this.$http
        .post(this.$api + '/pc/smsCode', {
          phone: this.addFormsi.phone,
        })
        .then((res) => {
          if (res.status != 200) return this.$message.error(res.data)
          console.log(res,'12312')
					this.timecodei = true;
					this.numi = 60;
					this.myTimei = setInterval(() => {
						if(this.numi == 0) {
							clearInterval(this.myTimei)
							this.timecodei = false;
							this.numi = 60;
						}else {
						this.numi = this.num - 1
						}
						
					}, 1000);
        })
        .catch((err) => {
          return this.$message.error(err.data)
        })
    },
    getYzmt() {
      // 获取验证码
      if (this.addForms.phone == '')
        return this.$message.error('请填写手机号！')
      this.$http
        .post(this.$api + '/pc/smsCode', {
          phone: this.addForms.phone,
        })
        .then((res) => {
          if (res.status != 200) return this.$message.error(res.data)
          console.log(res,'12312')
					this.timecode = true;
					this.num = 60;
					this.myTime = setInterval(() => {
						if(this.num == 0) {
							clearInterval(this.myTime)
							this.timecode = false;
							this.num = 60;
						}else {
						this.num = this.num - 1
						}
						
					}, 1000);
        })
        .catch((err) => {
          return this.$message.error(err.data)
        })
    },
  },
}
</script>
<style Lang="less" scoped>
.box22 {
   padding:30px 40px;
   box-sizing: border-box;
    height: 160px;
    color: #333333;
    font-size: 14px;
    opacity: 0.75;
    border-radius: 10px;
    margin: 0;
  }
.myi {
  width: 150px;
  border: 1px solid #3e8dff;
  background: #ffffff !important;
  background-color: #ffffff !important;
  border-radius:4px 0 0 4px;
  z-index: 1;
}
.fxr {
  padding: 0 20px;
  background: #fff;
  border: 1px solid #dddddd;
  position: fixed;
  right: 0;
  z-index: 100;
  top: 50%;
  transform: translate(0,-50%)
}
.tab {
  padding: 20px 0 20px 0;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  
  display: flex;
  flex-wrap: wrap;
}
.tabitem:nth-child(3n) {
  margin-right: 0;
}
.tabs {
  margin-right:20px;
  cursor: pointer;
  padding:4px;
  min-width: 40px;
  text-align:center;
  color:#3E8DFF;
  border-bottom: 3px solid #3E8DFF;
}
.tabn {
  padding:4px;
  cursor: pointer;
  margin-right:20px;
  min-width: 40px;
}
.inpy {
  color: #dedede;
  width: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
  border: 1px solid #adadad;
  border-radius: 3px;
}

.inpyy {
  color: #dedede;
  width: 100%;
  padding: 10px 12px;
  box-sizing: border-box;
  background: #F6F6F6;
  /* border: 1px solid #adadad; */
  border-radius: 3px;
}
.vtv {
  position: absolute;
  z-index: 10px;
  box-sizing: border-box;
  width: 102%;
  top: 37px;
  left: -4px;
  color: #333333;
  background: #ffffff;
  border-radius: 6px;
}
.tabitem {
  width: 32%;
  display: inline-block;
  margin-right: 2%;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 3px;
  margin-top: 20px;
}
.www {
    width: 100%;
    height:450px;
    background:url(../assets/image/foterBg.png) no-repeat;
    background-size:100% 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-top: 100px;
    /* position: relative;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/p-default/images/act/a.png', sizingMethod='scale'); */
}
.itmtop {
  padding: 0 12px;
  height: 74px;
  overflow: hidden;
  font-size: 20px;
  background: rgb(231,244,254);
  
}
.tyty {
  width: 16px;
  height:16px;
  margin-right:6px;
  position: absolute;
  right: -20px;
  bottom: 0;
}
.yzm {
  background: url('../assets/image/yzm.png') 0px 0px;
  text-align: center;
  width: 200px;
  font-style: italic;
  font-weight: bold;
  color: #3a3838;
  font-size: 26px;
  height: 40px;
  border: 1px solid rgb(184, 184, 184);
  display: inline-block;
}
.icn {
  padding: 4px 20px;
  border-radius: 3px;
  background: #eeeeee;
}
.bottom {
  padding: 0px 36px;
  cursor: pointer;
}
.input {
  border: 1px solid #d4d4d4;
  width: 400px;
}
.fffs {
  position: absolute;
  top: -20px;
  z-index: -1;
}
.fit {
  width:31%;
  box-sizing: border-box;
  text-align: center
}
.box1 {
    width: 15%;
    box-sizing: border-box;
    background: #cccccc;
    height: 370px;
    overflow-y: scroll;
    overflow-x: hidden;
    color: #ffffff;
    border-radius: 6px 0px 0 6px;
}
.opop {
  width: 23%;
  position: absolute;
  z-index: 10;
  top: 8%;
  left: 12%;
  box-sizing: border-box;
  border-radius: 8px;
  /* padding: 18px 10px 0 10px; */
  text-align: center;
}
.bg {
  background: #ffffff;
  padding: 6px 20px;
}
.tcc{
    display: flex;
    padding: 10px 8px 30px 8px;
    /* justify-content: space-between; */
    align-items: center;
    flex-wrap: wrap;
}
.mores {
  position: absolute;
  bottom: 6px;
  right: 8px;
}
.cuu {
  margin-right: 5%;
  border:1px solid #eeeeee;
  text-align: center;
  cursor: pointer;
  border-radius: 2px;
  width:30%;
  padding:6px;
  box-sizing: border-box;
  overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-bottom: 20rpx;
}
.tcc :nth-child(3n) {
  margin-right: 0;
}
.myttt {
  border: 1px solid #3E8DFF;
  border-radius: 6px;
  width: 300px;
  padding: 13px 0;
  display: flex;
  justify-content: center;
  align-items: center
}

.infof {
  position: absolute;
  top:0;
  padding: 12px 16px;
  background: rgb(255, 255, 255);
}

.tyt2 {
  position: absolute;
  height: 100%;
  width: 500px;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  color: #333333;
  padding: 12px 20px;
  top: 0;
  left: 90%;
  box-sizing: border-box;
}

.tyt1 {
  position: absolute;
  height: 100%;
  width: 600px;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  color: #333333;
  padding: 12px 20px;
  top: 0;
  left: 288px;
  box-sizing: border-box;
}

.tyt {
  position: absolute;
  height: 100%;
  width: 600px;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  color: #333333;
  padding: 12px 20px;
  top: 0;
  z-index: 1000;
  left: 138px;
  box-sizing: border-box;
}

.item {
  line-height: 40px;
  padding: 0 24px;
}
.items {
  padding: 6px 0px;
  margin-left: 28px;
}
.box2 {
  width: 85%;
  box-sizing: border-box;
}
.txs {
  border-bottom: 1px solid #3e8dff;
  cursor: pointer;
}
.home-container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.ql {
  width: 76%;
  box-sizing: border-box;
  margin: 0 auto;
}
.btty {
  padding: 4px 13px;
  display: inline-block;
  border: 1px solid #a1a1a1;
  border-radius: 30px;
}
.tttqq {
  position: absolute;
  top: 16px;
  left: -130px;
}
.tttqq1 {
  position: absolute;
  top: 40px;
  left: -110px;
}
.ywis {
  font-size: 18px;
  font-weight: 600;
  margin-right: 120px;
  text-align: center;
}
.tio1 {
  width:19%;
  padding:30px 0;
  text-align: center;
  border-radius: 8px;
  box-sizing: border-box;
  background: linear-gradient(#D0FFE8,#ffffff);
}
.tio2 {
  width:19%;
  text-align: center;
  border-radius: 8px;
  padding:30px 0;
  box-sizing: border-box;
  background: linear-gradient(#E2F2FE,#ffffff);
}
.tio3 {
  width:19%;
  text-align: center;
  border-radius: 8px;
  padding:30px 0;
  box-sizing: border-box;
  background: linear-gradient(#FFFADB,#ffffff);
}
.tio4 {
  width:19%;
  text-align: center;
  border-radius: 8px;
  padding:30px 0;
  box-sizing: border-box;
  background: linear-gradient(#E2F2FE,#ffffff);
}
.tio5 {
  width:19%;
  text-align: center;
  border-radius: 8px;
  padding:30px 0;
  box-sizing: border-box;
  background: linear-gradient(#FFEEE2,#ffffff);
}
.ywi {
  font-size: 18px;
  font-weight: 400;
  margin-right: 120px;
  text-align: center;
}
.dddww {
      position: absolute;
    top: 210px;
    left: -102px;
    color: #333333;
    z-index: 12;
    width: 160px;
}
.bttt {
  border-bottom: 1px solid #fafafa59;
  padding-bottom: 26px;
  margin-bottom: 26px;
}
.xcx {
  position: relative;
  text-align: center;
  transform: translate(-50%,0)
}
.fotter-container {
  width: 76%;
  margin: 0 auto;
  padding: 30px 0;
  box-sizing: border-box;
}
.btncc {
    position: absolute;
    right: 10px;
    top: 9px;
    cursor: pointer;
}

.bbb {
  width:19%;
  text-align: center;
  box-sizing:border-box;
  border: 1px solid #d4d4d4;
  border-radius: 6px;
  padding: 30px 0 40px 0;
}

.topu {
  border-bottom: 1px solid #ffffff;
  padding-bottom: 12px;
  margin-bottom: 12px;
  font-size: 17px;
}

.aaa {
  width:19%;
  background: #3E8DFF;
  color: #fff;
  box-sizing:border-box;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  align-items: center;
  border-radius: 6px;
}

.el-header {
  padding: 14px 12%;
  color: #FFFFFF;
  font-size: 16px;
}
span {
  margin-left: 15px;
}

.el-aside {
  background-color: #333744;
}
.el-menu {
  border-right: none;
  height: 95%;
  overflow: auto;
}
.el-menu ::-webkit-scrollbar {
  display: none;
}

.el-main {
  background-color: #eaedf1;
}
.iconfont {
  margin-right: 10px;
}
.toggle-button {
  background-color: #4a5064;
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 24px;

  letter-spacing: 0.2em;
  cursor: pointer;
}
</style>

<style lang="css">
.el-form-item__label {
  text-align: left !important;
}

.el-dialog__header {
  padding: 0 !important;
}

a {
  color: #888888;
  text-decoration: none;
}
.klk .el-input__inner {
  background: #FFFFFF;
}
.kkk .el-input__inner {
  background: #FFFFFF;
  border:1px solid #9d9d9d;
  width:100%;
}
.el-input__inner {
  border: none;
  background: #F6F6F6;
  height: 38px;
  line-height: 38px;
}
.el-form-item__label {
  font-size: 18px;
  color: #333333;
}
.mydo {
  height: 80vh;
  overflow: scroll;
}
  .el-carousel__item:nth-child(2n) {
    background-color: #ffffff;
    border-radius:10px;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #ffffff;
    border-radius:10px;
  }
</style>

