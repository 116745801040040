import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Message } from 'element-ui';

import './assets/fonts/iconfont.css'
// 导入全局样式表
import './assets/css/global.css'
import TreeTable from 'vue-table-with-tree-grid'

import axios from 'axios'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    ak: 'ex6S5ooDSKa6ewEEeH2kLwTXe895S5mB'
});

Vue.use(ElementUI);

axios.defaults.baseURL = '/'
Vue.prototype.$api = ''; // 上线开启
// Vue.prototype.$api = '/api'; // 本地开发开启
axios.interceptors.request.use(config => {
  return config
})

axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况    
  error => {
    console.log(error,'接口错误')
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录                
        // 未登录则跳转登录页面，并携带当前页面的路径                
        // 在登录成功后返回当前页面，这一步需要在登录页操作。                
        // case 401:
        //   router.replace({
        //     path: '/login',
        //     query: {
        //       redirect: router.currentRoute.fullPath
        //     }
        //   });
        //   break;
          // 403 token过期                
          // 登录过期对用户进行提示                
          // 清除本地token和清空vuex中token对象                
          // 跳转登录页面                
        // case 403:
        //   Message({
        //     message: '登录过期，请重新登录',
        //     duration: 3000,
        //     forbidClick: true,
        //     customClass:'mzindex'
        //   });
        //   // 清除token                    
        //   localStorage.removeItem('token');
        //   store.commit('loginSuccess', null);
        //   // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
        //   setTimeout(() => {
        //     router.replace({
        //       path: '/login',
        //       query: {
        //         redirect: router.currentRoute.fullPath
        //       }
        //     });
        //   }, 1000);
        //   break;
          // 404请求不存在                
        case 404:
          Message({
            message: '网络请求不存在',
            duration: 3000,
            type: 'error',
            forbidClick: true,
            customClass:'mzindex'
          });
          break;
            // 400错误提示                
        case 400:
          Message({
            message: error.response.data,
            duration: 3000,
            type: 'error',
            forbidClick: true,
            customClass:'mzindex'
          });
          break;
          // 其他错误，直接抛出错误提示                
        default:
          Message({
            message: error.response.data,
            type: 'error',
            duration: 3000,
            forbidClick: true,
            customClass:'mzindex'
          });
      }
      return Promise.reject(error.response);
    }
  }
)
Vue.prototype.$http = axios;
Vue.config.productionTip = false;
Vue.component('tree-table', TreeTable);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
